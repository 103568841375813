var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"molecule-availability-space-filter-result",class:{
    available: _vm.isAvailable,
    leased: !_vm.isAvailable,
    selected: _vm.selectedSpace?.id === _vm.displayData?.id
  },on:{"click":() => {
      if (_vm.isSelectedSpace) {
        _vm.unselectSpace()
        return
      }
      _vm.selectSpace()
    },"mouseenter":_vm.highlightSpace,"mouseleave":_vm.unHighlightSpace}},[_c('div',{staticClass:"body-col building-name-style"},[_vm._v("\n    "+_vm._s(_vm.displayData?.floor?.building?.name)+" "+_vm._s(_vm.displayData?.alias)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"body-col"},[_vm._v("\n    "+_vm._s(_vm.displayData.floor.no)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"body-col"},[_vm._v(_vm._s(_vm.displayData.util_sqm)+" "+_vm._s(_vm.$t('sqm')))]),_vm._v(" "),_c('div',{staticClass:"body-col"},[_vm._v(_vm._s(_vm.displayData.price.toLocaleString('de-DE'))+" € +"+_vm._s(_vm.$t('VAT')))]),_vm._v(" "),_c('div',{staticClass:"body-col"},[_vm._v("\n    "+_vm._s(_vm.$t(_vm.displayData.orientation))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"body-col buttons-list"},[(!_vm.disableButtonsActions.exploreSpacePage)?_c('AtomsCommonAtomSimpleButton',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.$t('explore')),expression:"$t('explore')",modifiers:{"left":true}}],attrs:{"icon-left":"view_in_ar","just-icon":true},on:{"click":function($event){return _vm.exploreSpace(_vm.displayData.id)}}}):_vm._e(),_vm._v(" "),(
        !_vm.requestOfferSpaces.find((sl) => sl.id === _vm.displayData.id) &&
        !_vm.disableButtonsActions.requestOffer &&
        _vm.shortlistState
      )?_c('AtomsCommonAtomSimpleButton',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.$t('addToList')),expression:"$t('addToList')",modifiers:{"left":true}}],staticClass:"green-button",attrs:{"icon-left":"checklist","just-icon":true},on:{"click":function($event){return _vm.addSpaceToCart(_vm.displayData, _vm.displayData.floor, _vm.displayBuildingData, $event)}}}):_vm._e(),_vm._v(" "),(
        _vm.requestOfferSpaces.find((sl) => sl.id === _vm.displayData.id) &&
        !_vm.disableButtonsActions.requestOffer &&
        _vm.shortlistState
      )?_c('AtomsCommonAtomSimpleButton',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.$t('removeFromList')),expression:"$t('removeFromList')",modifiers:{"left":true}}],staticClass:"red-button red-background",attrs:{"icon-left":"delete","just-icon":true},on:{"click":function($event){return _vm.removeSpaceFromCart(_vm.displayData, _vm.displayData.floor, _vm.displayBuildingData, $event)}}}):_vm._e(),_vm._v(" "),(!_vm.disableButtonsActions.requestOffer && !_vm.shortlistState)?_c('AtomsCommonAtomSimpleButton',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.$t('requestOffer')),expression:"$t('requestOffer')",modifiers:{"left":true}}],staticClass:"green-button",attrs:{"icon-left":"checklist","just-icon":true},on:{"click":function($event){return _vm.openRequestOfferModal(_vm.displayData, _vm.displayBuildingData, $event)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }