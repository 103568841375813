var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"molecule-availability-space-filter-result-building",class:{
    available: _vm.isAvailable,
    leased: !_vm.isAvailable,
    selected: _vm.selectedSpace?.id === _vm.displayData?.id
  },on:{"click":() => {
      if (_vm.isSelectedSpace) {
        _vm.unselectSpace()
        return
      }
      _vm.selectSpace()
    },"mouseenter":_vm.highlightSpace,"mouseleave":_vm.unHighlightSpace}},[_c('div',{staticClass:"body-col building-name-style"},[_vm._v("\n    "+_vm._s(_vm.displayData?.floor?.building?.name)+" "+_vm._s(_vm.displayData?.alias)+"\n  ")]),_vm._v(" "),_vm._l((_vm.listSpaceDetails),function(listDetail,i){return _c('MoleculesProjectBuildingV2MoleculeSidebarResultSpaceDetails',{key:i,attrs:{"type":listDetail,"space":_vm.displayData,"show-people-label":true}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }