import { render, staticRenderFns } from "./MoleculeAvailabilityFilterResultsList.vue?vue&type=template&id=32509e71&scoped=true&"
import script from "./MoleculeAvailabilityFilterResultsList.vue?vue&type=script&lang=js&"
export * from "./MoleculeAvailabilityFilterResultsList.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeAvailabilityFilterResultsList.vue?vue&type=style&index=0&id=32509e71&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32509e71",
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeAvailabilityFilterResultsList.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2FbuildingV2%2FMoleculeAvailabilityFilterResultsList.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingV2MoleculeAvailabilitySpaceFilterResultHeader: require('/app/components/molecules/project/buildingV2/MoleculeAvailabilitySpaceFilterResultHeader.vue').default,MoleculesProjectBuildingV2MoleculeAvailabilitySpaceFilterResult: require('/app/components/molecules/project/buildingV2/MoleculeAvailabilitySpaceFilterResult.vue').default})
