import { render, staticRenderFns } from "./MoleculeExtraSidebarComponentsMobile.vue?vue&type=template&id=f3a6a354&"
import script from "./MoleculeExtraSidebarComponentsMobile.vue?vue&type=script&lang=js&"
export * from "./MoleculeExtraSidebarComponentsMobile.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeExtraSidebarComponentsMobile.vue?vue&type=style&index=0&id=f3a6a354&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeExtraSidebarComponents.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2FbuildingV2%2FMoleculeExtraSidebarComponentsMobile.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomSquare: require('/app/components/atoms/common/AtomSquare.vue').default,MoleculesProjectBuildingV2MoleculeChooseViewBuildings: require('/app/components/molecules/project/buildingV2/MoleculeChooseViewBuildings.vue').default,MoleculesProjectBuildingV2MoleculeChoosePhases: require('/app/components/molecules/project/buildingV2/MoleculeChoosePhases.vue').default})
